
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { Action } from "vuex-module-decorators";

import Profile from "./organisation/Profile.vue";
import Method from "./organisation/Method.vue";


export default defineComponent({
  name: "organisation",
  components: {
    // Field,
    // Form,
    // ErrorMessage,
    Profile,
    // Method,
  },
  data() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const organisation_id = route.params.organisation_id ?? null;

    // const organisation = {
    //   name: "",
    //   address: "",
    //   latitude: "",
    //   longitude: "",
    //   raw: {
    //     slot_sets: {
    //       monday: null,
    //       tuesday: null,
    //       wednesday: null,
    //       thursday: null,
    //       friday: null,
    //       saturday: null,
    //       sunday: null,
    //     },
    //   },
    // };

    // const slotSetsMonday = [];
    // const slotSetsTuesday = [];
    // const slotSetsWednesday = [];
    // const slotSetsThursday = [];
    // const slotSetsFriday = [];
    // const slotSetsSaturday = [];
    // const slotSetsSunday = [];

    // const services = {
    //   pfp: true,
    //   urushelp: true,
    //   finskill_hh: true,
    //   finskill_sme: true,
    //   finplan: true,
    //   finsco_hh: true,
    //   myknp: true,
    //   finres_hh: true,
    //   finres_sme_soleprop: true,
    //   finres_sme: true,
    //   smehd: true,
    //   revmort: true,
    // };

    // const methods = {
    //   walk_in: true,
    //   phone: true,
    //   vc: true,
    // };

    // const validationSchema = Yup.object().shape({
    //   profile: Yup.object().shape({
    //     email: Yup.string()
    //       .email()
    //       .required("Please enter a valid Email")
    //       .nullable()
    //       .label("Email"),
    //   }),
    // });

    return {
      i18n: useI18n(),
      store,
      organisation_id
      // validationSchema,
      // organisation_id,
      // organisation,
      // slotSetsMonday,
      // slotSetsTuesday,
      // slotSetsWednesday,
      // slotSetsThursday,
      // slotSetsFriday,
      // slotSetsSaturday,
      // slotSetsSunday,
      // services,
      // methods,
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("Organisation", ["Administration"]);

      // this.store
      //   .dispatch(Actions.GET_ORGANISATION, this.organisation_id)
      //   .then(() => {
      //     this.organisation = this.store.getters.getOrganisationData;
      //   });

      // this.store.dispatch(Actions.GET_SLOT_SETS).then(() => {
      //   let slots = this.store.getters.getSlotSetsData;

      //   for (let index of Object.keys(slots)) {
      //     if (slots[index].is_monday == 1) {
      //       this.slotSetsMonday.push({
      //         value: slots[index].id,
      //         label: slots[index].name,
      //       });
      //     }
      //     if (slots[index].is_tuesday == 1) {
      //       this.slotSetsTuesday.push({
      //         value: slots[index].id,
      //         label: slots[index].name,
      //       });
      //     }
      //     if (slots[index].is_wednesday == 1) {
      //       this.slotSetsWednesday.push({
      //         value: slots[index].id,
      //         label: slots[index].name,
      //       });
      //     }
      //     if (slots[index].is_thursday == 1) {
      //       this.slotSetsThursday.push({
      //         value: slots[index].id,
      //         label: slots[index].name,
      //       });
      //     }
      //     if (slots[index].is_friday == 1) {
      //       this.slotSetsFriday.push({
      //         value: slots[index].id,
      //         label: slots[index].name,
      //       });
      //     }
      //     if (slots[index].is_saturday == 1) {
      //       this.slotSetsSaturday.push({
      //         value: slots[index].id,
      //         label: slots[index].name,
      //       });
      //     }
      //     if (slots[index].is_sunday == 1) {
      //       this.slotSetsSunday.push({
      //         value: slots[index].id,
      //         label: slots[index].name,
      //       });
      //     }
      //   }
      // });
    },
    onSubmit(values) {
      Swal.fire({
        text: "Organisation Details has been updated",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });

      // this.store.dispatch(Actions.STORE_PROFILE, values).then(() => {
      //   Swal.fire({
      //     text: "Your profile has been updated",
      //     icon: "success",
      //     buttonsStyling: false,
      //     confirmButtonText: "OK",
      //     customClass: {
      //       confirmButton: "btn fw-bold btn-light-primary",
      //     },
      //   }).then(() => {
      //     this.$router.go(0);
      //   });
      // });
    },
  },
});
