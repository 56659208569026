
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Slots from "./Slots.vue";

export default defineComponent({
  name: "organisation-profile",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueQrcode,
    Slots,
  },
  props: [""],
  setup(props, { emit }) {
    const formProValidator = Yup.object({
      organisation: Yup.object({
        name: Yup.string().required().label("Organisation Name"),
        address: Yup.string().required().label("Address"),
      }),
    });

    return {
      formProValidator,
    };
  },
  data() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const organisation_id = route.params.organisation_id ?? null;

    const organisation = {
      name: "",
      address: "",
      latitude: "",
      longitude: "",
      org_type: null,
      raw: null,
      parent_id: null,
      allocated_slot: 1,
      enabled: 1,
    };

    const slotAllocations = [{ value: 1 }, { value: 2 }, { value: 3 }];

    const active = [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" },
    ];

    const slotSetsMonday = [
      {
        value: null,
        label: "Not Available",
      },
    ];
    const slotSetsTuesday = [
      {
        value: null,
        label: "Not Available",
      },
    ];
    const slotSetsWednesday = [
      {
        value: null,
        label: "Not Available",
      },
    ];
    const slotSetsThursday = [
      {
        value: null,
        label: "Not Available",
      },
    ];
    const slotSetsFriday = [
      {
        value: null,
        label: "Not Available",
      },
    ];
    const slotSetsSaturday = [
      {
        value: null,
        label: "Not Available",
      },
    ];
    const slotSetsSunday = [
      {
        value: null,
        label: "Not Available",
      },
    ];

    const organisation_type = [
      { id: "branch", name: "Branch" },
      { id: "fa_office", name: "Office" },
    ];

    const branches = [];

    return {
      dataReady: false,
      store,
      organisation,
      organisation_id,
      slotSetsMonday,
      slotSetsTuesday,
      slotSetsWednesday,
      slotSetsThursday,
      slotSetsFriday,
      slotSetsSaturday,
      slotSetsSunday,
      organisation_type,
      branches,
      slotAllocations,
      active,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_BRANCHES).then(() => {
        this.branches = this.store.getters.getBranchesData;
      });
      if (this.organisation_id !== null) {
        this.store
          .dispatch(Actions.GET_ORGANISATION, this.organisation_id)
          .then(() => {
            this.organisation = this.store.getters.getOrganisationData;

            if (this.organisation.raw == null) {
              this.organisation.raw = {
                slot_sets: {
                  monday: null,
                  tuesday: null,
                  wednesday: null,
                  thursday: null,
                  friday: null,
                  saturday: null,
                  sunday: null,
                },
              };
            }

            console.log(this.organisation);

            this.dataReady = true;
          });

        this.store.dispatch(Actions.GET_SLOT_SETS).then(() => {
          let slots = this.store.getters.getSlotSetsData;

          if (slots.length > 0) {
            for (let index of Object.keys(slots)) {
              if (slots[index].is_monday == 1) {
                this.slotSetsMonday.push({
                  value: slots[index].id,
                  label: slots[index].name,
                });
              }
              if (slots[index].is_tuesday == 1) {
                this.slotSetsTuesday.push({
                  value: slots[index].id,
                  label: slots[index].name,
                });
              }
              if (slots[index].is_wednesday == 1) {
                this.slotSetsWednesday.push({
                  value: slots[index].id,
                  label: slots[index].name,
                });
              }
              if (slots[index].is_thursday == 1) {
                this.slotSetsThursday.push({
                  value: slots[index].id,
                  label: slots[index].name,
                });
              }
              if (slots[index].is_friday == 1) {
                this.slotSetsFriday.push({
                  value: slots[index].id,
                  label: slots[index].name,
                });
              }
              if (slots[index].is_saturday == 1) {
                this.slotSetsSaturday.push({
                  value: slots[index].id,
                  label: slots[index].name,
                });
              }
              if (slots[index].is_sunday == 1) {
                this.slotSetsSunday.push({
                  value: slots[index].id,
                  label: slots[index].name,
                });
              }
            }
          }
        });
      } else {
        this.dataReady = true;
      }
    },
    updateProfile(values) {
      console.log(this.organisation);
      const profileForm = this.$refs.proForm as any;
      profileForm.validate().then(({ valid }) => {
        if (valid) {
          if (this.organisation_id) {
            this.store
              .dispatch(Actions.UPDATE_ORGANISATION, values.organisation)
              .then(() => {
                Swal.fire({
                  text: "Organisation Details have been updated",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(() => {
                  this.$router.go(0);
                });
              });
          } else {
            this.store
              .dispatch(Actions.STORE_ORGANISATION, values.organisation)
              .then(() => {
                Swal.fire({
                  text: "Organisation Details have been updated",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                  },
                }).then(() => {
                  this.$router.go(0);
                });
              });
          }
        } else {
          profileForm?.$el?.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
  async mounted() {
    this.init();
  },
});
