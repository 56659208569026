
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";

export default defineComponent({
  name: "organisation-profile",
  components: {
    Form,
    Field,
    // ErrorMessage,
  },
  props: [""],
  setup(props, { emit }) {
    const formMethodValidator = Yup.object({
      // organisation: Yup.object({
      //   name: Yup.string().required().label("Organisation Name"),
      //   address: Yup.string().required().label("Address"),
      // }),
    });

    return {
      formMethodValidator,
    };
  },
  data() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const organisation_id = route.params.organisation_id ?? null;
    const calendar = {} as any;
    const closeCalendar = {} as any;
    const slot_sets = {} as any;
    const isCalendarReady = false;
    const slot_sets_list = {} as any;
    const selectedMonth = 10;
    const selectedCloseMonth = null;
    const months = [
      { value: 1, label: "January" },
      { value: 2, label: "February" },
      { value: 3, label: "March" },
      { value: 4, label: "April" },
      { value: 5, label: "May" },
      { value: 6, label: "June" },
      { value: 7, label: "July" },
      { value: 8, label: "August" },
      { value: 9, label: "September" },
      { value: 10, label: "October" },
      { value: 11, label: "November" },
      { value: 12, label: "December" },
    ];

    const selectedYear = 2023;
    const selectedCloseYear = 2023;
    const years = [
      { value: 2023, label: "2023" },
      { value: 2024, label: "2024" },
      { value: 2025, label: "2025" },
    ];

    return {
      store,
      organisation_id,
      calendar,
      slot_sets,
      isCalendarReady,
      slot_sets_list,
      months,
      selectedMonth,
      selectedCloseMonth,
      selectedYear,
      selectedCloseYear,
      years,
      closeCalendar,
      availableCloseDates: [],
      isCloseCalendarAvailable: false,
    };
  },
  methods: {
    init() {
      this.store
        .dispatch(Actions.GET_ORGANISATION, this.organisation_id)
        .then(() => {
          //this.organisation = this.store.getters.getOrganisationData;
          // console.log(this.store.getters.getOrganisationData);
          this.slot_sets = this.store.getters.getOrganisationData.raw?.slot_sets;
          // console.log(this.slot_sets);
        });

      this.store.dispatch(Actions.GET_SLOT_SETS).then(() => {
        this.slot_sets_list = this.store.getters.getSlotSetsData;
      });

      setTimeout(() => {
        // this.calendar = this.getDaysArray(this.year, 1);
        //console.log(this.calendar);
      }, 500);
    },
    // updateSlot(values) {
    //   // console.log(this.calendar);

    //   let paylod = [];

    //   for (let cal of Object.keys(this.calendar)) {
    //     paylod.push({
    //       organisation_id: this.organisation_id,
    //       year_month: this.year + "-" + ("0" + this.selectedMonth).slice(-2),
    //       date_available: this.calendar[cal].date,
    //       // raw:this.calendar[cal].,
    //     });
    //   }

    // const profileForm = this.$refs.methodForm as any;
    // profileForm.validate().then(({ valid }) => {
    //   if (valid) {
    //     this.store
    //       .dispatch(Actions.UPDATE_ORGANISATION, values.organisation)
    //       .then(() => {
    //         Swal.fire({
    //           text: "Your profile has been updated",
    //           icon: "success",
    //           buttonsStyling: false,
    //           confirmButtonText: "OK",
    //           customClass: {
    //             confirmButton: "btn fw-bold btn-light-primary",
    //           },
    //         }).then(() => {
    //           this.$router.go(0);
    //         });
    //       });
    //   } else {
    //     profileForm?.$el?.scrollIntoView({ behavior: "smooth" });
    //   }
    // });
    // },
    // getDaysArray(year, month) {
    //   var monthIndex = month - 1; // 0..11 instead of 1..12
    //   var names = [
    //     "sunday",
    //     "monday",
    //     "tuesday",
    //     "wednesday",
    //     "thursday",
    //     "friday",
    //     "saturday",
    //   ];
    //   var date = new Date(year, monthIndex, 1);
    //   var result = [];
    //   while (date.getMonth() == monthIndex) {
    //     let slots = {
    //       isAvalaible: this.checkDayAvailibility(names[date.getDay()]),
    //       date: date.getDate(),
    //       day: names[date.getDay()],
    //       allocate: 2,
    //       slots: this.getSlots(names[date.getDay()]),
    //     };
    //     // result.push(date.getDate() + "-" + names[date.getDay()]);
    //     result.push(slots);
    //     date.setDate(date.getDate() + 1);
    //   }
    //   this.isCalendarReady = true;
    //   return result;
    // },
    checkDayAvailibility(day) {
      let av = true;
      if (this.slot_sets[day] == null) av = false;
      return av;
    },
    getSlots(day) {
      let slotId = this.slot_sets[day];
      let slot = this.slot_sets_list[slotId];
      let slotDetails = {
        slots: slot?.raw ? slot?.raw : null,
        allocate: 2,
      };
      if (slotDetails.slots) {
        for (let ind of Object.keys(slotDetails.slots)) {
          slotDetails.slots[ind].allocate = 2;
          slotDetails.slots[ind].booked = 0;
        }
        // console.log(slotDetails.slots);
      }

      // for (let ind of Object.keys(slotDetails.slots)) {
      //   // if(slotDetails[ind]){
      //     console.log(slotDetails.slots)
      //   //   for (let ind2 of Object.keys(slotDetails[ind].slots)) {
      //   //   console.log(slotDetails[ind].slots[ind2])
      //   // }
      //   // }
      // }

      return slotDetails;
    },
    // changeCalendar() {
    //   this.calendar = this.getDaysArray(2022, this.selectedMonth);
    //   this.isCalendarReady = false;
    //   // console.log(this.isCalendarReady);
    //   // console.log(this.getDaysArray(2022, this.selectedMonth));
    // },
    // updateAvailableSlots(index) {
    //   // console.log(this.calendar[index]);
    //   // console.log(this.calendar[index].slots.slots[0].allocate);
    //   // console.log(this.calendar[index + 1].slots.slots[0].allocate);
    //   for (let ind of Object.keys(this.calendar[index].slots.slots)) {
    //     this.calendar[index].slots.slots[ind].allocate = this.calendar[
    //       index
    //     ].allocate;
    //   }
    // },
    getAppDate() {
      let payload = {
        organisation_id: this.organisation_id,
        year: this.selectedCloseYear,
        month: this.selectedCloseMonth,
      };
      this.store.dispatch(Actions.GET_ORG_APP_DATE, payload).then(() => {
        let appDate = this.store.getters.getOrgAppDateData;

        this.isCloseCalendarAvailable = false;
        this.closeCalendar = {};
        this.availableCloseDates = [];

        if ((appDate.result === "Success")) {
          for (let ind of Object.keys(appDate.data)) {
            this.availableCloseDates.push(
              parseInt(
                moment(appDate.data[ind].date_available, "YYYY-MM-DD").format(
                  "D"
                )
              )
            );
          }

          this.closeCalendar = this.getCloseCalendar(
            this.selectedCloseYear,
            this.selectedCloseMonth
          );
          this.isCloseCalendarAvailable = true;

          console.log(this.closeCalendar);
        }
      });
    },
    getCloseCalendar(year, month) {
      var monthIndex = month - 1; // 0..11 instead of 1..12
      var names = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      var date = new Date(year, monthIndex, 1);
      var result = [];
      while (date.getMonth() == monthIndex) {
        let slots = {
          isAvalaible: this.checkCloseDayAvailibility(date.getDate()),
          date: date.getDate(),
          day: names[date.getDay()],
        };
        result.push(slots);
        date.setDate(date.getDate() + 1);
      }
      return result;
    },

    checkCloseDayAvailibility(date) {
      let av = false;
      if (this.availableCloseDates.includes(date)) av = true;
      return av;
    },
    onSubmitCloseDate() {
      let disabled_date = [];

      for (let cal of Object.keys(this.closeCalendar)) {
        if (!this.closeCalendar[cal].isAvalaible) {
          disabled_date.push(this.closeCalendar[cal].date);
        }
      }

      const payload = {
        month: this.selectedCloseMonth,
        year: this.selectedCloseYear,
        disabled_date: disabled_date,
        organisation_id: this.organisation_id,
      };

      this.store.dispatch(Actions.UPDATE_APP_DATES, payload).then(() => {
        let response = this.store.getters.getCloseAppDatesResponse;
        console.log(response.result);

        if (response.result == "Success") {
          Swal.fire({
            title: "Success",
            text:
              "Appointment Dates have been successfully upodated for all Branches",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Error",
            text:
              "Appointment Date have not been set for the selected month and year. If you want to Set Appointment Dates, please go to the Set Appointment Dates section above.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      });
    },
  },
  async mounted() {
    this.init();
  },
});
